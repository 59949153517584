import {interpolate, useCurrentFrame} from 'remotion';

export const WallRight: React.FC = () => {
	const frame = useCurrentFrame();

	const lightWall = interpolate(
		frame,
		[0, 30, 31, 60, 61, 70, 71, 80, 81, 90, 91],
		[0.07, 0.07, 0.15, 0.15, 0.18, 0.18, 0.22, 0.22, 0.26, 0.26, 0.3],
		{
			extrapolateLeft: 'clamp',
			extrapolateRight: 'clamp',
		},
	);

	return (
		<g id="wall-right" style={{opacity: lightWall}}>
			<path
				id="&lt;Path&gt;"
				className="s2181"
				d="m2634.7 397.3l-7.9 290.6-2.9 110.9c-8.4 310.7 53.4 471.1-287.6 315.5-143.1-65.3-216.5-96.1-216.2-107.1 0 0 19.2-246.3 20.1-329 0.9-83.2-12.8-334.5-12.8-334.5 11-13 56.9-35.4 214.4-90 310.2-107.5 298.3-57.9 292.9 143.6z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2182"
				d="m2626.7 398.8c-0.1 0.4-7.7 287.2-7.8 289 0 1.3-3 111.8-3.1 112.7-8.2 305.1 52.4 463.7-282.3 311.4-140.4-64-212.5-94.1-212.3-105 0 0 19-246.1 20-328.7 0.9-83.1-12.5-334.1-12.5-334.1 10.8-12.9 56-34.8 210.6-88.3 304.5-105.3 292.7-55.1 287.4 143z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2183"
				d="m2618.6 400.3c0 0.9-7.5 283.8-7.6 287.4 0 2.6-3.1 112.6-3.2 114.5-8.1 299.5 51.4 456.4-277 307.2-137.8-62.6-208.7-92.1-208.4-102.8 0 0 18.8-245.8 19.8-328.3 0.9-83.1-12.2-333.9-12.2-333.9 10.6-12.6 55.1-34.1 206.7-86.5 298.9-103 287.2-52.4 281.9 142.4z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2184"
				d="m2610.6 401.8c0 1.3-7.3 280.4-7.4 285.8-0.2 3.9-3.3 113.5-3.4 116.4-7.9 293.8 50.3 449-271.8 303-135.1-61.2-204.8-90.1-204.5-100.7 0 0 18.6-245.6 19.6-328 1-83-11.8-333.5-11.8-333.5 10.3-12.5 54.2-33.5 202.9-84.8 293.1-100.8 281.6-49.6 276.4 141.8z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2185"
				d="m2602.6 403.3c0 1.7-7.2 277-7.3 284.2-0.2 5.2-3.4 114.4-3.5 118.2-7.8 288.2 49.3 441.7-266.6 298.9-132.4-59.9-200.9-88.2-200.6-98.6 0 0 18.5-245.3 19.5-327.7 1-82.9-11.5-333.1-11.5-333.1 10.1-12.3 53.3-33 199-83.1 287.5-98.5 276.1-46.8 271 141.2z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2186"
				d="m2594.6 404.8c0 2.1-7 273.6-7.2 282.6-0.2 6.5-3.5 115.2-3.6 120-7.7 282.6 48.2 434.4-261.3 294.8-129.8-58.6-197.1-86.2-196.8-96.6 0 0 18.3-245 19.4-327.2 1-82.8-11.2-332.8-11.2-332.8 9.9-12.2 52.4-32.4 195.2-81.4 281.8-96.2 270.5-44 265.5 140.6z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2187"
				d="m2586.6 406.3c-0.1 2.5-6.8 270.3-7.1 281.1-0.2 7.7-3.6 116-3.8 121.7-7.4 277 47.3 427-256 290.7-127.1-57.3-193.1-84.2-192.9-94.5 0 0 18.2-244.7 19.3-326.9 1-82.7-10.8-332.5-10.8-332.5 9.6-11.9 51.4-31.7 191.2-79.6 276.1-94 265-41.3 260.1 140z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2188"
				d="m2578.6 407.8c-0.1 2.9-6.7 266.9-7 279.5-0.3 9-3.7 116.9-3.9 123.6-7.3 271.2 46.3 419.6-250.7 286.5-124.5-56-189.3-82.3-189.1-92.4 0 0 18-244.5 19.1-326.6 1.1-82.6-10.4-332.1-10.4-332.1 9.4-11.8 50.5-31.2 187.4-77.9 270.4-91.7 259.4-38.5 254.6 139.4z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2189"
				d="m2570.6 409.3c-0.1 3.3-6.5 263.5-6.9 277.9-0.3 10.3-3.8 117.7-4 125.4-7.2 265.6 45.2 412.3-245.5 282.3-121.8-54.6-185.4-80.2-185.1-90.2 0 0 17.8-244.3 18.9-326.3 1.1-82.5-10.1-331.7-10.1-331.7 9.2-11.7 49.6-30.6 183.6-76.2 264.7-89.4 253.8-35.7 249.1 138.8z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2190"
				d="m2562.6 410.7c-0.1 3.8-6.4 260.2-6.8 276.4-0.3 11.6-3.9 118.6-4.1 127.2-7 260 44.2 405-240.2 278.2-119.2-53.2-181.6-78.3-181.3-88.1 0 0 17.6-244 18.8-325.9 1.1-82.5-9.8-331.4-9.8-331.4 9-11.5 48.7-30 179.7-74.5 259-87.2 248.3-33 243.7 138.1z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2191"
				d="m2554.6 412.2c-0.2 4.2-6.2 256.8-6.7 274.8-0.4 12.9-4 119.5-4.2 129.1-6.9 254.3 43.1 397.5-235 274-116.4-51.9-177.7-76.3-177.4-86 0 0 17.5-243.8 18.7-325.6 1.1-82.4-9.5-331.1-9.5-331.1 8.8-11.3 47.8-29.3 175.9-72.7 253.3-84.9 242.7-30.2 238.2 137.5z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2192"
				d="m2546.5 413.7c-0.1 4.6-6 253.5-6.5 273.3-0.4 14.1-4.1 120.2-4.3 130.8-6.7 248.7 42.1 390.2-229.8 269.9-113.7-50.6-173.7-74.3-173.5-83.9 0 0 17.3-243.5 18.5-325.3 1.2-82.2-9.1-330.7-9.1-330.7 8.6-11.1 46.9-28.8 172-71 247.7-82.7 237.2-27.4 232.7 136.9z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2193"
				d="m2538.5 415.2c-0.1 5.1-5.8 250.1-6.4 271.7-0.4 15.4-4.2 121.1-4.5 132.6-6.5 243.1 41.2 382.9-224.4 265.8-111.1-49.3-169.9-72.4-169.7-81.8 0 0 17.2-243.3 18.4-325 1.2-82.1-8.8-330.3-8.8-330.3 8.4-11 46-28.2 168.2-69.3 241.9-80.4 231.6-24.6 227.2 136.3z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2194"
				d="m2530.5 416.7c-0.1 5.5-5.7 246.7-6.3 270.1-0.5 16.7-4.2 122-4.6 134.4-6.4 237.5 40.1 375.6-219.2 261.7-108.4-48-166-70.4-165.8-79.8 0 0 17.1-242.9 18.3-324.5 1.2-82.1-8.5-330.1-8.5-330.1 8.2-10.7 45.1-27.8 164.3-67.5 236.1-78.6 226.1-21.9 221.8 135.7z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2195"
				d="m2522.5 418.2c-0.1 5.9-5.5 243.3-6.2 268.5-0.5 18-4.3 122.9-4.7 136.3-6.2 231.8 39.1 368.2-213.9 257.4-105.8-46.6-162.2-68.4-161.9-77.6 0 0 16.8-242.7 18.1-324.2 1.2-82-8.2-329.7-8.2-329.7 8-10.6 44.2-27.2 160.5-65.8 230.4-76.4 220.5-19.1 216.3 135.1z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2196"
				d="m2514.5 419.7c-0.2 6.3-5.4 239.9-6.1 266.9-0.5 19.3-4.4 123.7-4.8 138.1-6.1 226.1 38 360.8-208.7 253.3-103.1-45.2-158.3-66.4-158-75.5 0 0 16.7-242.4 17.9-323.9 1.3-81.9-7.8-329.3-7.8-329.3 7.8-10.5 43.3-26.7 156.6-64.1 224.8-74.2 215-16.3 210.9 134.5z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2197"
				d="m2506.5 421.2c-0.2 6.7-5.2 236.5-6 265.4-0.6 20.6-4.5 124.5-4.9 139.8-6 220.5 37 353.5-203.4 249.2-100.5-43.9-154.4-64.4-154.2-73.4 0 0 16.5-242.2 17.8-323.6 1.3-81.8-7.5-328.9-7.5-328.9 7.6-10.3 42.4-26.1 152.8-62.4 219-71.9 209.4-13.6 205.4 133.9z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2198"
				d="m2498.5 422.7c-0.2 7.1-5.1 233.2-5.9 263.8-0.6 21.9-4.6 125.4-5.1 141.6-5.7 214.9 36.1 346.2-198.1 245.1-97.8-42.6-150.5-62.5-150.3-71.3 0 0 16.4-241.9 17.7-323.2 1.3-81.7-7.2-328.7-7.2-328.7 7.4-10.1 41.5-25.5 149-60.6 213.3-69.7 203.8-10.8 199.9 133.3z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2199"
				d="m2490.5 424.1c-0.2 7.6-4.9 229.9-5.8 262.3-0.6 23.2-4.7 126.2-5.2 143.5-5.6 209.2 35 338.8-192.9 240.9-95.1-41.3-146.6-60.5-146.4-69.2 0 0 16.2-241.7 17.6-322.9 1.3-81.6-6.9-328.3-6.9-328.3 7.2-9.9 40.6-24.9 145.1-58.9 207.6-67.5 198.3-8 194.5 132.6z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2200"
				d="m2482.5 425.6c-0.3 8-4.8 226.5-5.7 260.7-0.7 24.5-4.8 127.1-5.3 145.3-5.5 203.6 34 331.4-187.6 236.8-92.5-40-142.8-58.6-142.6-67.1 0 0 16.1-241.5 17.4-322.6 1.4-81.5-6.5-327.9-6.5-327.9 6.9-9.8 39.7-24.4 141.3-57.2 201.9-65.3 192.7-5.2 189 132z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2201"
				d="m2474.4 427.1c-0.2 8.4-4.5 223.1-5.5 259.1-0.7 25.8-4.9 128-5.4 147.1-5.3 198 32.7 324.6-182.4 232.6-89.9-38.4-138.9-56.5-138.6-64.9 0 0 15.8-241.2 17.2-322.3 1.4-81.4-6.2-327.5-6.2-327.5 6.7-9.6 38.7-23.8 137.4-55.5 196.2-63 187.2-2.5 183.5 131.4z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2202"
				d="m2466.4 428.6c-0.2 8.8-4.4 219.7-5.4 257.5-0.7 27.1-5 128.8-5.5 148.9-5.2 192.4 31.7 317.3-177.1 228.5-87.3-37-135-54.5-134.8-62.8 0 0 15.7-241 17.1-321.9 1.4-81.4-5.9-327.3-5.9-327.3 6.5-9.4 37.8-23.2 133.6-53.7 190.5-60.8 181.6 0.3 178 130.8z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2203"
				d="m2458.4 430.1c-0.2 9.3-4.2 216.3-5.3 256-0.7 28.3-5.1 129.6-5.6 150.7-5.1 186.7 30.6 309.9-171.9 224.3-84.5-35.7-131.1-52.6-130.9-60.8 0 0 15.5-240.6 17-321.5 1.4-81.3-5.5-326.9-5.5-326.9 6.2-9.2 36.8-22.6 129.6-52 184.9-58.6 176 3.1 172.6 130.2z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2204"
				d="m2450.4 431.6c-0.3 9.7-4.1 213-5.2 254.4-0.8 29.6-5.2 130.5-5.8 152.5-4.8 181.1 29.7 302.6-166.5 220.2-81.9-34.4-127.3-50.6-127.1-58.7 0 0 15.4-240.4 16.8-321.2 1.5-81.1-5.1-326.5-5.1-326.5 6-9.1 35.9-22 125.8-50.3 179.1-56.4 170.4 5.8 167.1 129.6z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2205"
				d="m2442.4 433.1c-0.3 10.1-3.9 209.6-5.1 252.8-0.8 30.9-5.3 131.3-5.9 154.3-4.7 175.4 28.6 295.3-161.3 216.1-79.2-33.1-123.4-48.7-123.2-56.6 0 0 15.2-240.1 16.7-320.8 1.5-81.1-4.8-326.3-4.8-326.3 5.8-8.9 35-21.4 122-48.5 173.4-54.1 164.8 8.6 161.6 129z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2206"
				d="m2434.4 434.6c-0.3 10.5-3.8 206.2-5 251.2-0.8 32.2-5.3 132.2-6 156.1-4.6 169.8 27.6 288-156.1 211.9-76.5-31.6-119.5-46.6-119.3-54.4 0 0 15.1-239.9 16.6-320.5 1.5-81-4.5-325.9-4.5-325.9 5.6-8.7 34.1-20.8 118.1-46.8 167.7-51.9 159.3 11.4 156.2 128.4z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2207"
				d="m2426.4 436.1c-0.3 10.9-3.6 202.8-4.9 249.6-0.9 33.5-5.4 133.1-6.1 158-4.4 164.1 26.5 280.6-150.8 207.7-73.9-30.3-115.6-44.6-115.4-52.3 0 0 14.8-239.6 16.4-320.2 1.5-80.9-4.2-325.5-4.2-325.5 5.4-8.6 33.2-20.3 114.3-45.1 162-49.7 153.7 14.2 150.7 127.8z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2208"
				d="m2418.4 437.5c-0.3 11.4-3.5 199.5-4.8 248.2-0.9 34.7-5.5 133.8-6.2 159.7-4.3 158.5 25.5 273.3-145.6 203.6-71.2-29-111.7-42.7-111.5-50.2 0 0 14.7-239.4 16.3-319.9 1.5-80.8-3.9-325.1-3.9-325.1 5.2-8.4 32.3-19.7 110.4-43.4 156.3-47.5 148.2 16.9 145.3 127.1z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2209"
				d="m2410.4 439c-0.4 11.8-3.3 196.2-4.7 246.6-0.9 36-5.6 134.7-6.3 161.5-4.2 152.9 24.5 266-140.3 199.5-68.6-27.7-107.9-40.7-107.7-48.1 0 0 14.5-239.2 16.1-319.5 1.6-80.7-3.5-324.9-3.5-324.9 5-8.2 31.4-19.1 106.6-41.6 150.6-45.2 142.6 19.7 139.8 126.5z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2210"
				d="m2402.3 440.5c-0.3 12.2-3 192.8-4.5 245-1 37.3-5.7 135.6-6.5 163.3-3.9 147.3 23.5 258.6-135 195.4-65.9-26.3-104-38.8-103.8-46 0 0 14.4-238.9 16-319.2 1.6-80.6-3.2-324.5-3.2-324.5 4.8-8 30.5-18.5 102.7-39.9 145-43 137.1 22.5 134.3 125.9z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2211"
				d="m2394.3 442c-0.3 12.6-2.9 189.4-4.4 243.4-1 38.6-5.8 136.5-6.6 165.2-3.8 141.6 22.5 251.2-129.7 191.2-63.3-25-100.2-36.8-100-43.9 0 0 14.3-238.7 15.9-318.9 1.6-80.5-2.9-324.1-2.9-324.1 4.6-7.9 29.6-18 98.9-38.2 139.2-40.8 131.5 25.2 128.8 125.3z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2212"
				d="m2386.3 443.5c-0.3 13-2.7 186-4.2 241.8-1.1 39.9-6 137.3-6.8 167-3.7 136 21.4 243.9-124.5 187-60.6-23.6-96.2-34.7-96.1-41.8 0 0 14.1-238.3 15.8-318.5 1.6-80.4-2.6-323.7-2.6-323.7 4.4-7.7 28.7-17.4 95.1-36.5 133.5-38.5 125.9 28 123.3 124.7z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2213"
				d="m2378.3 445c-0.4 13.5-2.6 182.6-4.1 240.2-1.2 41.2-6.1 138.2-6.9 168.8-3.5 130.3 20.4 236.6-119.3 182.9-57.9-22.3-92.3-32.8-92.1-39.7 0 0 13.9-238.1 15.5-318.1 1.7-80.4-2.2-323.5-2.2-323.5 4.2-7.5 27.8-16.8 91.2-34.7 127.9-36.3 120.4 30.8 117.9 124.1z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2214"
				d="m2370.3 446.5c-0.4 13.9-2.4 179.2-4 238.7-1.2 42.5-6.2 139-7 170.5-3.4 124.7 19.3 229.3-114 178.8-55.3-20.9-88.5-30.8-88.3-37.6 0 0 13.7-237.8 15.4-317.8 1.7-80.3-1.9-323.1-1.9-323.1 4-7.4 26.9-16.2 87.4-33 122.1-34.1 114.8 33.6 112.4 123.5z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2215"
				d="m2362.3 448c-0.4 14.3-2.3 175.9-3.9 237.1-1.2 43.8-6.3 139.8-7.2 172.4-3.2 119 18.4 221.9-108.7 174.6-52.6-19.6-84.6-28.9-84.4-35.5 0 0 13.6-237.6 15.3-317.5 1.7-80.1-1.6-322.7-1.6-322.7 3.7-7.2 26-15.6 83.5-31.3 116.5-31.9 109.3 36.3 107 122.9z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2216"
				d="m2354.3 449.5c-0.4 14.7-2.1 172.5-3.8 235.5-1.2 45.1-6.4 140.7-7.3 174.2-3 113.4 17.3 214.6-103.4 170.5-50-18.3-80.8-26.9-80.6-33.4 0 0 13.4-237.3 15.2-317.2 1.7-80-1.3-322.4-1.3-322.4 3.5-7 25.2-15 79.7-29.5 110.7-29.6 103.7 39.1 101.5 122.3z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2217"
				d="m2346.3 450.9c-0.4 15.2-2 169.2-3.7 234-1.3 46.4-6.5 141.6-7.4 176-2.9 107.8 16.3 207.3-98.2 166.4-47.3-17-76.8-25-76.7-31.3 0 0 13.3-237.1 15-316.8 1.8-80-0.9-322.1-0.9-322.1 3.3-6.8 24.3-14.4 75.8-27.8 105.1-27.4 98.2 41.9 96.1 121.6z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2218"
				d="m2338.3 452.4c-0.5 15.6-1.8 165.8-3.6 232.4-1.3 47.7-6.6 142.4-7.5 177.9-2.8 102.1 15.3 199.9-92.9 162.1-44.7-15.5-73-22.9-72.9-29.1 0 0 13.1-236.8 14.9-316.5 1.8-79.9-0.6-321.7-0.6-321.7 3.1-6.7 23.4-13.9 72-26.1 99.4-25.1 92.6 44.6 90.6 121z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2219"
				d="m2330.2 453.9c-0.4 16-1.6 162.4-3.4 230.9-1.3 48.9-6.6 143.2-7.6 179.6-2.6 96.5 14.2 192.6-87.7 158-42-14.2-69.1-20.9-68.9-27 0 0 12.9-236.6 14.7-316.2 1.8-79.8-0.3-321.3-0.3-321.3 2.9-6.5 22.5-13.3 68.2-24.4 93.7-22.9 87 47.4 85 120.4z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2220"
				d="m2322.2 455.4c-0.4 16.4-1.4 159.1-3.3 229.3-1.4 50.2-6.7 144.1-7.7 181.4-2.5 90.9 13.2 185.3-82.5 153.9-39.3-12.9-65.2-19-65-24.9 0 0 12.7-236.4 14.6-315.9 1.8-79.7 0.1-321 0.1-321 2.6-6.3 21.5-12.6 64.2-22.7 88-20.6 81.5 50.3 79.6 119.9z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2221"
				d="m2314.2 456.9c-0.4 16.8-1.3 155.7-3.2 227.7-1.4 51.5-6.8 144.9-7.9 183.2-2.3 85.3 12.2 178-77.1 149.8-36.7-11.6-61.4-17-61.2-22.9 0 0 12.6-236 14.4-315.4 1.9-79.6 0.5-320.7 0.5-320.7 2.4-6.1 20.6-12.1 60.4-21 82.3-18.3 75.9 53.1 74.1 119.3z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2222"
				d="m2306.2 458.4c-0.5 17.2-1.1 152.3-3.1 226.1-1.4 52.8-6.9 145.8-8 185.1-2.1 79.5 11.2 170.6-71.9 145.6-33.9-10.3-57.4-15.1-57.3-20.8 0 0 12.4-235.8 14.3-315.1 1.9-79.5 0.8-320.3 0.8-320.3 2.2-6 19.7-11.5 56.5-19.3 76.7-16.1 70.4 55.8 68.7 118.7z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2223"
				d="m2298.2 459.9c-0.5 17.7-1 148.9-3 224.5-1.5 54.1-7 146.7-8.1 186.9-2 73.9 10.2 163.3-66.6 141.5-31.3-8.9-53.6-13.1-53.5-18.7 0 0 12.3-235.5 14.2-314.8 1.9-79.4 1.1-319.9 1.1-319.9 2-5.9 18.8-10.9 52.7-17.6 71-13.8 64.8 58.6 63.2 118.1z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2224"
				d="m2290.2 461.4c-0.5 18.1-0.8 145.5-2.9 222.9-1.5 55.4-7.1 147.5-8.2 188.7-1.9 68.3 9.2 156-61.4 137.3-28.6-7.5-49.7-11-49.6-16.5 0 0 12.1-235.3 14.1-314.4 1.9-79.4 1.4-319.7 1.4-319.7 1.8-5.6 17.9-10.3 48.8-15.8 65.4-11.6 59.3 61.4 57.8 117.5z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2225"
				d="m2282.2 462.9c-0.5 18.5-0.7 142.1-2.8 221.4-1.5 56.6-7.2 148.3-8.3 190.4-1.7 62.7 8.1 148.6-56.1 133.2-26-6.2-45.9-9.1-45.7-14.4 0 0 11.9-235 13.8-314.1 2-79.3 1.8-319.3 1.8-319.3 1.6-5.5 17-9.7 45-14.1 59.7-9.3 53.7 64.1 52.3 116.9z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2226"
				d="m2274.2 464.3c-0.6 19-0.5 138.9-2.7 219.9-1.6 57.9-7.3 149.2-8.4 192.3-1.6 57 7.1 141.2-50.9 129-23.3-4.9-42-7.1-41.8-12.3 0 0 11.7-234.8 13.7-313.8 2-79.2 2.1-318.9 2.1-318.9 1.4-5.3 16.2-9.1 41.1-12.4 54.1-7 48.2 66.9 46.9 116.2z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2227"
				d="m2266.1 465.8c-0.5 19.4-0.3 135.5-2.5 218.3-1.6 59.2-7.4 150.1-8.6 194.1-1.4 51.4 6.2 133.9-45.6 124.9-20.6-3.6-38-5.2-37.9-10.2 0 0 11.6-234.5 13.6-313.5 2-79 2.4-318.5 2.4-318.5 1.2-5.2 15.3-8.5 37.3-10.7 48.4-4.7 42.6 69.7 41.3 115.6z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2228"
				d="m2258.1 467.3c-0.5 19.8-0.1 132.1-2.4 216.7-1.6 60.5-7.5 150.9-8.7 195.9-1.2 45.8 5.2 126.5-40.3 120.8-17.9-2.3-34.2-3.2-34.1-8.1 0 0 11.5-234.3 13.5-313.1 2-79 2.7-318.3 2.7-318.3 1-4.9 14.4-7.8 33.5-8.9 42.7-2.4 37 72.5 35.8 115z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2229"
				d="m2250.1 468.8c-0.5 20.2 0 128.7-2.3 215.1-1.7 61.8-7.6 151.8-8.8 197.7-1.1 40.2 4.2 119.1-35.1 116.7-15.2-1-30.3-1.2-30.2-6.1 0 0 11.3-233.9 13.4-312.7 2-78.9 3-317.9 3-317.9 0.8-4.8 13.6-8.2 29.6-7.2 37.1 2.3 31.5 75.2 30.4 114.4z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2230"
				d="m2242.1 470.3c-0.5 20.6 0.2 125.3-2.2 213.6-1.7 63.1-7.7 152.6-8.9 199.5-0.9 34.4 3.2 111.6-29.8 112.4-12.6 0.4-26.5 0.8-26.4-3.9 0 0 11.2-233.7 13.2-312.4 2.1-78.8 3.4-317.5 3.4-317.5 0.6-4.6 12.7-6.5 25.8-5.5 31.5 2.4 25.9 78 24.9 113.8z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2231"
				d="m2234.1 471.8c-0.6 21 0.3 121.9-2.1 212-1.7 64.4-7.7 153.4-9 201.3-0.8 28.8 2.2 104-24.6 108.3-9.9 1.6-22.6 2.8-22.4-1.8 0 0 10.9-233.5 13-312.1 2.1-78.7 3.7-317.2 3.7-317.2 0.3-4.4 11.8-5.6 21.9-3.7 25.9 5.1 20.4 80.8 19.5 113.2z"
			/>
			<path
				id="&lt;Path&gt;"
				className="s2232"
				d="m2226.1 473.3c-0.6 21.4 0.5 118.6-2 210.4-1.7 65.7-7.8 154.3-9.2 203.1-0.6 23.2 1.2 96.3-19.2 104.2-7.3 2.8-18.7 4.7-18.6 0.3 0 0 10.8-233.2 12.9-311.7 2.1-78.7 4-316.9 4-316.9 0.1-4.3 10.9-4.8 18.1-2 20 7.9 14.8 83.5 14 112.6z"
			/>
		</g>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
