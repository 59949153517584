import {Easing, interpolate, useCurrentFrame} from 'remotion';

export const LightLeft: React.FC = () => {
	const frame = useCurrentFrame();

	const spotOn = interpolate(frame, [30, 31], [0, 0.2], {
		easing: Easing.bezier(0.7, 0.0, 0, 0.5),
		extrapolateLeft: 'clamp',
		extrapolateRight: 'clamp',
	});

	return (
		<g id="lights-left">
			<g id="&lt;Group&gt;">
				<path
					id="light"
					className="s2233"
					style={{filter: 'blur(15px)', opacity: spotOn}}
					d="m454 961.5c-16.2 0-29.3-67.1-29.3-149.9 0-82.8 13.1-149.9 29.3-149.9 16.2 0 29.4 67.1 29.4 149.9 0 82.8-13.2 149.9-29.4 149.9z"
				/>
				<g id="&lt;Group&gt;">
					<g id="&lt;Group&gt;">
						<path
							id="&lt;Path&gt;"
							className="s2239"
							d="m475.9 657.9h-19.5v-31.8h19.5z"
						/>
						<path
							id="&lt;Path&gt;"
							className="s2235"
							d="m475.9 657.9h-19.5v-2.8h19.5z"
						/>
						<path
							id="&lt;Path&gt;"
							className="s2236"
							d="m472.8 659.4h-13.3v-2.1h13.3z"
						/>
					</g>
					<path
						id="&lt;Path&gt;"
						className="s2237"
						d="m458.9 642.5h-38.2v-4.2h38.2z"
					/>
				</g>
				<path
					id="shadow-spot"
					className="s2238"
					style={{filter: 'blur(15px)'}}
					d="m362.9 643.2c0-25.7 20.8-46.6 46.6-46.6 25.7 0 46.5 20.9 46.5 46.6 0 25.7-20.8 46.5-46.5 46.5-25.8 0-46.6-20.8-46.6-46.5z"
				/>
			</g>
		</g>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
