import React from 'react';
import {Composition, Folder} from 'remotion';

import {AnimatedScene} from './LyonJS100/AnimatedScene';
import {LyonJS100Sponsor, Sponsor} from './LyonJS100/Sponsor';
import {LyonJS100Talk, Talk} from './LyonJS100/Talk';
import {ReplayLyonJS} from './Replay';

export const LyonJSComposition: React.FC = () => {
	return (
		<Folder name="LyonJS">
			<Composition
				width={1920}
				height={1080}
				id="LyonJSReplay"
				fps={60}
				durationInFrames={320}
				component={ReplayLyonJS}
				defaultProps={{
					title: 'Meetup #80 : Projet XState',
					speakers: [
						{
							name: 'Maxime Blanc',
							picture:
								'https://ca.slack-edge.com/T108ZKPMF-U3VL71HHU-179292d5d86f-512',
						},
					],
					date: '8 férvrier 2023',
					sponsor:
						'https://s3.eu-west-3.amazonaws.com/moovijob.prod/1494438/Horizontal_Black_Logo-Zenika.png',
				}}
			/>
			<Folder name="LyonJS100">
				<Composition
					width={1920}
					height={1080}
					id="AnimatedScene"
					fps={60}
					durationInFrames={360}
					component={AnimatedScene}
				/>
				<Composition
					width={1920}
					height={1080}
					id="LyonJS100Sponsor"
					fps={60}
					durationInFrames={320}
					component={Sponsor}
					schema={LyonJS100Sponsor}
					defaultProps={{
						labelSponsor: 'Gold',
						labeSponsorColor: '#efdb50',
						labelFontSize: 60,
						sponsorLogoUrl:
							'https://www.remotion.dev/assets/images/shortvid-logo-648b24f40aea2fb96bf6e2404c3712d7.png',
						sponsorLogoWidth: 900,
					}}
				/>
				<Composition
					width={1920}
					height={1080}
					id="LyonJS100Talk"
					fps={60}
					durationInFrames={320}
					component={Talk}
					schema={LyonJS100Talk}
					defaultProps={{
						speakers: [
							{
								picture: 'https://github.com/JonnyBurger.png',
								name: 'Jonny Burger',
							},
						],
						talkTitle: 'How to build a video editor in React',
						titleSize: 60,
					}}
				/>
			</Folder>
		</Folder>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
