import React from 'react';
import {
	Easing,
	Img,
	interpolate,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';

import {SpeakerSchema} from '../../showcases.types';

import {SpeakerName} from './Talk/SpeakerName';
import {TalkTitle} from './Talk/TalkTitle';
import {AnimatedScene} from './AnimatedScene';

export const LyonJS100Talk = z.object({
	speakers: z.array(SpeakerSchema),
	talkTitle: z.string(),
	titleSize: z.number().finite().positive().gte(30).optional(),
});

export const Talk: React.FC<z.infer<typeof LyonJS100Talk>> = ({
	speakers,
	talkTitle,
	titleSize,
}) => {
	const {fps} = useVideoConfig();
	const frame = useCurrentFrame();
	const startFrame = 170;

	const scaleUpAnimation = spring({
		frame: frame - startFrame,
		from: 0,
		to: 1,
		fps,
		durationInFrames: 50,
	});

	const opacityUp = spring({
		frame: frame - startFrame,
		from: 0,
		to: 0.9,
		fps,
		durationInFrames: 50,
	});

	const textOpacity = interpolate(
		frame,
		[startFrame, startFrame + 100],
		[0, 1],
		{
			extrapolateLeft: 'clamp',
			extrapolateRight: 'clamp',
			easing: Easing.bezier(0.33, 1, 0.68, 1),
		},
	);

	return (
		<AnimatedScene>
			<div
				style={{
					width: '100%',
					display: 'flex',
				}}
			>
				<div>
					{speakers.map((speaker, index) => {
						const [firstName, ...lastName] = speaker.name.split(' ');

						const firstNameContent = firstName;
						const lastNameContent = lastName.join(' ');

						return (
							<div key={index}>
								<Img
									src={speaker.picture}
									alt="Speaker Picture"
									style={{
										width: 'auto',
										height: 460,
										marginLeft: 120,
										marginTop: 90,
										transform: `rotate(-5deg) scale(${scaleUpAnimation})`,
										borderRadius: 0,
										opacity: opacityUp,
										border: `10px solid black`,
									}}
								/>
								<SpeakerName
									scaleUpAnimation={scaleUpAnimation}
									opacityAnimation={opacityUp}
									firstNameContent={firstNameContent}
									lastNameContent={lastNameContent}
								/>
							</div>
						);
					})}
				</div>
			</div>
			<TalkTitle
				opacity={textOpacity}
				titleSize={titleSize}
				talkTitle={talkTitle}
			/>
		</AnimatedScene>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
