import React from 'react';
import {Composition, Folder} from 'remotion';

import {Talk} from './talk/Talk';
import {TalkSchema} from './talk/talks.types';

export const MeetupToolKit: React.FC = () => {
	return (
		<Folder name="Meetup-ToolKit">
			<Folder name="HumanTalk">
				<Composition
					component={Talk}
					width={1200}
					height={1200}
					id="HumanTalk-Talk"
					fps={30}
					durationInFrames={120}
					schema={TalkSchema}
					defaultProps={{
						speakersNames: 'John Doe',
						titleColor: '#772757',
						talkTitle: 'Is JS an awesome programing language ?',
						titleSize: 80,
						backgroundImg:
							'https://humantalks.com/assets/talks-10min-eabec95a48239e06c9dfb2c466874a3c734c54b579d38cf98a1135eb3f293c5e.jpg',
						speakerPicture:
							'https://raw.githubusercontent.com/lyonjs/shortvid.io/main/public/images/common/defaultAvatar.svg',
						eventLogo:
							'https://humantalks.com/assets/logo-HumanTalks-b238463e6389c198a989506119f06d0d0ef836cc90a3c7713939c0ae09eb5f76.png',
					}}
				/>
			</Folder>
			<Folder name="ParisJS">
				<Composition
					component={Talk}
					width={1200}
					height={1200}
					id="ParisJS-Talk"
					fps={30}
					durationInFrames={120}
					schema={TalkSchema}
					defaultProps={{
						speakersNames: 'John Doe',
						titleColor: '#e14680',
						talkTitle: 'Is JS an awesome programing language ?',
						titleSize: 80,
						backgroundImg:
							'https://secure.meetupstatic.com/photos/event/4/e/2/0/highres_466520000.webp',
						speakerPicture:
							'https://raw.githubusercontent.com/lyonjs/shortvid.io/main/public/images/common/defaultAvatar.svg',
						eventLogo:
							'https://secure-content.meetupstatic.com/images/classic-events/499980504/128x128.jpg',
					}}
				/>
			</Folder>
			<Folder name="StrasbourgJS">
				<Composition
					component={Talk}
					width={1200}
					height={1200}
					id="StrasbourgJS-Talk"
					fps={30}
					durationInFrames={120}
					schema={TalkSchema}
					defaultProps={{
						speakersNames: 'John Doe',
						titleColor: '#FFCC4A',
						talkTitle: 'Is JS an awesome programing language ?',
						titleSize: 80,
						backgroundImg:
							'https://secure.meetupstatic.com/photos/event/3/5/b/8/highres_415693752.webp',
						speakerPicture:
							'https://raw.githubusercontent.com/lyonjs/shortvid.io/main/public/images/common/defaultAvatar.svg',
						eventLogo:
							'https://secure-content.meetupstatic.com/images/classic-events/493856926/128x128.jpg',
					}}
				/>
			</Folder>
		</Folder>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
